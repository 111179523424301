import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/download',
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import(/* webpackChunkName: "Download" */ '@/views/download.vue'),
    meta: {
      name:'下载页',
    },
  },
  {
    path: '/qa-en',
    name: 'Qa',
    component: () => import(/* webpackChunkName: "Qa" */ '@/views/qa-en.vue'),
    meta: {
      name:'Q&A'
    },
  },
  {
    path: '/qa-id',
    name: 'Qa1',
    component: () => import(/* webpackChunkName: "Qa1" */ '@/views/qa-id.vue'),
    meta: {
      name:'Q&A'
    },
  },
  {
    path: '/test-flight-qa-en',
    name: 'test-flight-qa-en',
    component: () => import(/* webpackChunkName: "testFlightQaEn" */ '@/views/test-flight-qa-en.vue'),
    meta: {
      name:'Q&A'
    },
  },
  {
    path: '/test-flight-qa-id',
    name: 'test-flight-qa-id',
    component: () => import(/* webpackChunkName: "testFlightQaId" */ '@/views/test-flight-qa-id.vue'),
    meta: {
      name:'Q&A'
    },
  },
]

const router = new VueRouter({
  routes
})

export default router
