<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  data () {
    return {
    }
  },
  created() {
  },
  watch: {
  },
  methods: {
  },
  mounted () {}
}
</script>
<style lang="scss">
  #app{
    width: 100%;
    height: 100%;
  }
</style>
