import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/reset.css'
import axios from 'axios'
import { Button, Lazyload  } from 'vant';
import { Image as VanImage } from 'vant';
// 自动设置Rem基准值（html标签字体大小）
import 'amfe-flexible'
Vue.use(Button)
Vue.use(VanImage);
Vue.use(Lazyload )
Vue.config.productionTip = false
Vue.prototype.$axios = axios
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
