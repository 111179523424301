
const state = {

  selfInfo: {
    'uid': "",
    'token': "",
    'dev-brand': "",
    'dev-manufacturer': "",
    'dev-model': "",
    'dev-language': "",
    'dev-region': "",
    'dev-timezone': "",
    'dev-app-version': "",
    'dev-channel': "",
    'dev-package': "",
    'dev-id': "",
    'dev-pid': ""
  },
}

const mutations = {

  SET_SELFINFO: (state, selfInfo) => {
    state.selfInfo = selfInfo
  },

}



export default {
  namespaced: true,
  state,
  mutations,

}
